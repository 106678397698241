import React, { useEffect, useState } from "react";
import TimezoneSelect from "react-timezone-select";
import { patchApi, postApi } from "../../api/methods";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchUserInfo } from "../../store/features/user/userSlice";
import { changeTheme } from "../../store/features/common/commonSlice";
import axios from "axios";
import { BASE_URL } from "../../api/API";

const General = (props) => {
  const users = useSelector((state) => state.user);
  const isDarkStyle = useSelector((state) => state.common.isDark);
  const { layoutShift } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  // console.log({users});
  // alert(users.userChosenTopics.timeZone)
  const userInfo = users?.userData;
  const userTopics = users?.userChosenTopics;
  console.log("gggg", userTopics);
  let getTimeZone;
  if (userTopics) {
    getTimeZone = !userTopics?.timeZone ? "" : JSON.parse(userTopics?.timeZone);
  }
  // console.log('userTopics', userTopics.timeSlote);
  const [selectedTimezone, setSelectedTimezone] = useState(getTimeZone);
  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [isOn, setIsOn] = useState(userTopics?.isEvergreen);
  const [isDark, setIsDark] = useState(userTopics?.isDarkmode);
  const [isUpdate, setIsUpdate] = useState(false);
  const [evergreenSlots, setEvergreenSlots] = useState(
    userTopics?.evergreenSlots || []
  );

  useEffect(() => {
    // dispatch(fetchUserInfo(user?.userData?.linkedinId))
    // console.log('call 2',users?.userData?.linkedinId, userTopics)
    if (userTopics) {
      getTimeZone = !userTopics?.timeZone
        ? ""
        : JSON.parse(userTopics?.timeZone);
    }

    setSelectedTimes(userTopics?.timeSlote);
    setEvergreenSlots(userTopics?.evergreenSlots);
    setIsOn(userTopics?.isEvergreen);
    setSelectedTimezone(getTimeZone);

    // console.log('call 3',selectedTimes, isOn, isDark, isUpdate)
  }, [users?.userData?.linkedinId]);
  const handleEvergreenSlotChange = (selectedOptions) => {
    setShowUpdateButton(true);
    const selectedValues = selectedOptions?.map((option) => option.value);
    setEvergreenSlots(selectedValues);
    setIsUpdate(true);
  };
  // save the configuration of user
  const handleUpdateUser = async () => {
    try {
      await postApi("user/create-user-choosen-topics", {
        loggeduserId: userInfo?.linkedinId,
        isDarkmode: isDark,
        isEvergreen: isOn,
        timeZone: JSON.stringify(selectedTimezone),
        timeSlote: selectedTimes,
        evergreenSlots: evergreenSlots, // Add this line
      });

      toast.success("Updated!");
      setIsUpdate(false);
      // dispatch(fetchUserInfo());
      setIsOn(!isOn);
    } catch (error) {
      console.warn("myaccount/general/update", error);
      toast.error("Update failed!");
      setIsUpdate(false);
    }
  };
  // console.log({ isDark });

  const handleSwich = () => {
    setIsDark(!isDark);
    dispatch(changeTheme(isDark));
    localStorage.setItem("theme", isDark);
    setIsUpdate(true);
  };
  // Function to toggle the switch state
  const handleSwichs = () => {
    setIsOn(!isOn);
    setIsUpdate(true);
  };
  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);
    setIsUpdate(true);
  };
  // time slots in 24 hours
  function generateTimes() {
    const times = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute++) {
        const formattedHour = hour.toString().padStart(2, "0");
        const formattedMinute = minute.toString().padStart(2, "0");
        const time = `${formattedHour}:${formattedMinute}`;
        times.push({ label: time, value: time });
      }
    }

    return times;
  }
  const timeOptions = generateTimes();
  const [selectedTimes, setSelectedTimes] = useState(userTopics?.timeSlote); // Initialize as an empty array
  // console.log({selectedTimes});
  let timeSlotIteration;
  if (selectedTimes === undefined) {
    return;
  } else {
    timeSlotIteration = selectedTimes?.map((value) => ({
      label: value,
      value,
    }));
  }
  const handleTimeChange = (selectedOptions) => {
    // Extract the values (strings) from the selected options
    const selectedValues = selectedOptions?.map((option) => option.value);

    // Update the selectedTimes array with the selected values
    setSelectedTimes(selectedValues);
    setIsUpdate(true);
  };

  // console.log({ selectedTimes });
  // when user is not want to save these changes anymore
  const handleCancelUser = () => {
    setSelectedTimezone(getTimeZone);
    setIsOn(userTopics?.isEvergreen);
    setIsDark(userTopics?.isDarkmode);
    setSelectedTimes(userTopics?.timeSlote);
    setEvergreenSlots(userTopics?.evergreenSlots || []); // Add this line
    setIsUpdate(false);
  };

  // Delete the user account from this platform [ Note: not entirely delete the data only account status is false ]
  const deleteMyAccount = async () => {
    const confirmDeleteUser = confirm(
      "Are you sure you want to delete your account?"
    );
    if (confirmDeleteUser) {
      try {
        await patchApi(`user/user-session`, {
          ac_status: false,
          linkedinId: users.userData.linkedinId,
        });

        await axios.post(
          `${BASE_URL}userprofile/logout`,
          {},
          { withCredentials: true }
        );

        // Remove token from localStorage if needed
        // localStorage.removeItem('token');

        // Redirect to login page
        window.location.href = "/login";
      } catch (err) {
        console.error("Error deleting account:", err);
      }
    }
  };

  return (
    <>
      <div className="general-item py-2 mb-2 my-2">
        <h3 className="text-lg mb-1">Personal information</h3>
        <p className="user-info-account">{userInfo?.userName}</p>
      </div>
      <div className="general-item py-2 mb-2">
        <h3 className="text-lg mb-1">Email address</h3>
        <p className="user-info-account">{userInfo?.email}</p>
      </div>
      <div className="general-item row d-flex justify-content-between align-items-center py-2 mb-2">
        <div className="col-md-8">
          <h3 className="text-lg">Set Time Slot</h3>
        </div>
        <div className="col-md-4">
          <Select
            isMulti
            options={timeOptions}
            // value={selectedTimes.map(value => ({ label: value, value }))}
            value={timeSlotIteration}
            onChange={handleTimeChange}
            placeholder="Select a time as 24 Hours"
            className="time-slote shadow"
          />
        </div>
      </div>
      <div className="general-item row d-flex justify-content-between align-items-center py-2 mb-2">
        <div className="col-md-8">
          <h3>Timezone</h3>
        </div>
        <div className="col-md-4">
          <TimezoneSelect
            value={selectedTimezone}
            onChange={handleTimezoneChange}
            className="mb-3 shadow time-zone"
          />
        </div>
      </div>
      <div className="general-item-switch py-2 mb-2">
        <h3 className="flex items-center gap-2">
          Evergreen Slot
          <i
            class="fa fa-info-circle"
            style={{ fontSize: "16px" }}
            title="Your selected post will be reported on your wall"
          ></i>
        </h3>
        <div
          className="switch"
          onClick={handleSwichs}
        >
          <span className={isOn ? "black" : "white"}></span>
        </div>
      </div>
      {isOn ? (
        <>
      <div className="general-item row d-flex justify-content-between align-items-center py-2 mb-2">
        <div className="col-md-8">
          <h3 className="text-lg">Set Evergreen Time Slot</h3>
        </div>
        <div className="col-md-4">
          <Select
            isMulti
            options={timeOptions}
            value={evergreenSlots.map((value) => ({ label: value, value }))}
            onChange={handleEvergreenSlotChange}
            placeholder="Select evergreen time slots"
            className="time-slote shadow"
          />
        </div>
      </div>
      {/* <div className='py-2 general-item-switch mb-2'>
                <h3>Dark Mode</h3>
                <div className='d-flex align-items-center'>
                    <i className="fa-regular fa-sun" style={ isDarkStyle ? {color:'black'} : {color:'white'}}></i>
                    <div className='switch m-2' onClick={handleSwich}  >
                        <span className={!isDarkStyle ? 'black' : 'white'}  ></span>
                    </div>
                    <i className="fa-solid fa-moon" style={ isDarkStyle ? {color:'black'} : {color:'white'}}></i>
                </div>
                
            </div> */}
      
          {showUpdateButton ?(<div className="upDate-btn">
            <button onClick={handleUpdateUser}>Update</button>
            <button onClick={handleCancelUser}>Cancel</button>
          </div>):(<></>)}
        </>
      ) : (<>
      </>
      )}
       <>
       <div className="footer-general mb-4 mt-4">
  <div className="linkedin-branding" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div className="linkedin-image" style={{ marginRight: '10px' }}>
          <i className="fa-brands fa-linkedin fa-2x" style={{ color: '#2267dd' }}></i>
        </div>
        <h3 style={{ margin: '0 10px', fontSize: '16px' }}>
          Connected as <strong style={{ marginLeft: '5px', fontSize: '16px' }}>{userInfo?.userName}</strong>
        </h3>
      </div>
      <div className="footer-general-btn mt-2" style={{ display: 'flex', gap: '10px' }}>
        <button 
          className="btn btn-primary" 
          style={{ minWidth: '150px', whiteSpace: 'nowrap', fontSize: '14px' }}
          onClick={() => window.location.href = `${BASE_URL}auth/linkedin?isFromAccount=true`}
        >
          <i className="fa fa-refresh"></i>
          <span> Re Authenticate</span>
        </button>
        <button 
          className="btn btn-secondary" 
          style={{ minWidth: '150px', whiteSpace: 'nowrap', fontSize: '14px' }}
        >
          Disconnect
        </button>
      </div>
    </div>
  </div>
</div>




<div
  className="delete-user mb-1"
  style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Align items to the ends
    width: '100%', // Ensure it uses full width if needed
    marginTop:"40px"
  }}
>
  <div>
    <h3 className="text-lg mb-1">Danger Zone</h3>
    <p className="mb-2">Careful, these actions are permanent</p>
  </div>
  <span
    className="border-2 border-red-600 p-2 rounded-md"
    style={{
      backgroundColor: 'red',
      fontWeight: 300,
      color: 'white',
      cursor: 'pointer',
    }}
    onClick={deleteMyAccount}
  >
    Delete my Account
  </span>
</div>


        </>
    </>
  );
};

export default General;
