import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { setDateRange, fetchAnalyticsData } from '../../store/features/analytics/analyticsAction';
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Switch } from "../../components/ui/switch";
import { useMediaQuery } from 'react-responsive';

const BarChartComp = () => {
  const [openDateRange, setOpenDateRange] = useState(false);
  const { isDark } = useSelector((state) => state.common);
  const [showLikes, setShowLikes] = useState(true);
  const [showComments, setShowComments] = useState(true);
  const [showReactions, setShowReactions] = useState(true);
  const [selectedQuantity, setSelectedQuantity] = useState("impressions");
  const { impression,summary,followersGraph, userId, dateRange,engagement,dailyAnalytics } = useSelector((state) => state.analytics);
  const [data1, setData1] = useState([]);
  console.log(engagement);
  const userinfo = useSelector((state) => state.user.userData);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 1188px)' });
  

  useEffect(() => {
    // This effect runs once on component mount
    if (engagement) {
      handleDataFetching();
    }
  }, []); // Empty dependency array ensures this effect runs only once on mount
  
  useEffect(() => {
    // This effect runs whenever selectedQuantity or openDateRange changes
    if (engagement) {
      handleDataFetching();
    }
  }, [selectedQuantity, dateRange]); // Add engagement as a dependency if needed
  
  function handleDataFetching() {
    if (selectedQuantity === 'impressions' || selectedQuantity === 'engagements') {
      const data2 = formattData(engagement);
      const data3 = formatData(impression, showLikes, showComments);
      console.log("data4");
      const data4 = formatFollowersData(followersGraph);
      console.log(data4);
      console.log("data4");
      console.log(data2[selectedQuantity]);
      setData1(data2[selectedQuantity]); // Update state here inside useEffect
    } else if (selectedQuantity === "Followers") {
      console.log("data1");
      console.log(data1);
      console.log("data4");
      const data4 = formatFollowersData(followersGraph);
      console.log(data4.followers);
      setData1(data4.followers); // Update state here inside useEffect
    } else {
      const data3 = formatData(impression, showLikes, showComments, showReactions);
      const data6 = convertDataStructure(dailyAnalytics);
      console.log("data3");
      console.log(data3);
      console.log(data6);
      console.log("data3");
      setData1(data6); // Update state here inside useEffect
    }
  }  
  
  const convertDataStructure = (data) => {
    return data.map(item => ({
      name: item._id,
      value: item.likes + item.comments + item.reactions,
    }));
  };
  

  useEffect(() => {
    if (userId && dateRange) {
      const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
      const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);
      dispatch(fetchAnalyticsData(userId, formattedStartDate, formattedEndDate));
    }
  }, [userId, dateRange, dispatch]);

  const handleSelect = (ranges) => {
    dispatch(setDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    }));
  };

  const handleApply = () => {
    setShowComments(true);
    setShowLikes(true);
    setOpenDateRange(false);
  };

  const handleClick = () => {
    setOpenDateRange(!openDateRange);
  };

  const formatData = (data, showLikes, showComments, showReactions) => {
    data = data || [];
    const comments = data.comments || [];
    const likes = data.likes || [];
    const reactions = data.reactions || []; // Add this line for reactions
  
    const combined = [
      ...(showComments ? comments : []),
      ...(showLikes ? likes : []),
      ...(showReactions ? reactions : []), // Add reactions to the combined array if showReactions is true
    ];
    
    const result = [];
    combined.forEach((item) => {
      const existing = result.find((r) => r.name === item.date.slice(0, 10));
      if (existing) {
        existing.value += item.count;
      } else {
        result.push({ name: item.date.slice(0, 10), value: item.count });
      }
    });
  
    result.sort((a, b) => new Date(a.name) - new Date(b.name));
    return result;
  };
  

  function formattData(data) {
    const formattedData = {};
    data = data || [];
    data.forEach(item => {
        const date = item.date.substring(0, 10); // Extract 'YYYY-MM-DD' from the date string

        if (!formattedData[date]) {
            formattedData[date] = { impressions: 0, engagements: 0 };
        }

        formattedData[date].impressions += item.impressions;
        formattedData[date].engagements += item.engagements;
    });

    // Convert the aggregated result into the desired format
    const impressionsArray = [];
    const engagementsArray = [];

    for (const [date, counts] of Object.entries(formattedData)) {
        impressionsArray.push({ name: date, value: counts.impressions });
        engagementsArray.push({ name: date, value: counts.engagements });
    }

    return { impressions: impressionsArray, engagements: engagementsArray };
}

function formatFollowersData(data) {
  const formattedData = {};
  data = data || [];
  
  data.forEach(item => {
      const date = item.date.substring(0, 10); // Extract 'YYYY-MM-DD' from the date string

      if (!formattedData[date]) {
          formattedData[date] = { followers: 0 };
      }

      // Ensure that item.followers is a number before adding it
      const followersCount = parseInt(item.followers) || 0;
      formattedData[date].followers += followersCount;
  });

  // Convert the aggregated result into the desired format
  const followersArray = [];

  for (const [date, counts] of Object.entries(formattedData)) {
      followersArray.push({ name: date, value: counts.followers });
  }

  return { followers: followersArray };
}


  
  
const maxValue = Math.max(...data1.map((entry) => entry.value)); 
   const yAxisDomain = [0, maxValue + 10];


  return (
    <div className="flex flex-col items-center gap-4">
    <div
      className={`flex w-full items-center px-4 py-2 ${!isDark ? 'text-white bg-black-900' : 'text-black bg-gray-100'}`}
      style={{ borderRadius: '8px' }}
    >
      {/* Flexbox spacer to keep the title centered */}
      <div className="flex-1"></div>

      {/* Centered title with flex-1 on either side to space correctly */}
      <h3 className={`text-2xl font-bold ${!isDark ? 'text-white' : 'text-black'}`}>
        Overall Stats Tracking
      </h3>

      {/* Flex-grow to keep the select aligned to the right */}
      <div className="flex-1 text-right">
        <select
          onChange={(e) => setSelectedQuantity(e.target.value)}
          value={selectedQuantity}
          className={`p-2 border rounded ${!isDark ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
        >
          <option value="impressions">Impressions</option>
          <option value="engagements">Engagements</option>
          <option value="reactions">Reactions</option>
          <option value="comments">Comments</option>
          <option value="Followers">Followers</option>
        </select>
      </div>
    </div>

    <div className={`chart-box shadow-md p-4 w-full ${!isDark ? 'bg-[#2D2D2D] text-white' : 'bg-gray text-black'}`} style={{ borderRadius: '8px' }}>
      {isMobile ? (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data1}>
            <defs>
              <linearGradient id="barGradient" x1="0" y1="1" x2="1" y2="0">
                <stop offset="0.2" stopColor="#39B2FF" stopOpacity={1} />
                <stop offset="1" stopColor="#8A51FF" stopOpacity={1} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" axisLine={{ stroke: "#697381" }} tickLine={false} />
            <YAxis domain={yAxisDomain} axisLine={{ stroke: "#697381" }} tickLine={false} tickFormatter={(value) => `${value}`} tick={{ fill: isDark ? "black" : "white" }} />
            <CartesianGrid horizontal={true} vertical={false} stroke="#697381" />
            <Bar dataKey="value" radius={6} fill="url(#barGradient)" maxBarSize={100} />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={data1}>
            <defs>
              <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#8A51FF" stopOpacity={0.85} />
                <stop offset="100%" stopColor="#39B2FF" stopOpacity={0.85} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" axisLine={{ stroke: "#CCC" }} tickLine={false} tick={{ fill: isDark ? "black" : "white", fontWeight: "bold" }} />
            <YAxis domain={yAxisDomain} axisLine={{ stroke: "#CCC" }} tickLine={false} tickFormatter={(value) => value.toLocaleString()} tick={{ fill: isDark ? "black" : "white", fontWeight: "bold" }} />
            <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" stroke="#DDD" />
            <Bar dataKey="value" radius={[10, 10, 0, 0]} fill="url(#barGradient)" maxBarSize={80} animateNewValues />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  </div>

  
  );
};

export default BarChartComp;
