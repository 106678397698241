import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  allComments,
  postAnalytics,
  reactionSummary,
  userProfileData,
} from "../../store/features/analytics/analyticsAction";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { IMAGE_URL, apibase } from "../../api/API";

// Dummy data
const dummyUserData = {
  linkedinId: "dummyLinkedInId",
  firstname: "John",
  lastname: "Doe",
  currentPosition: "Software Engineer",
  linkedinProfile: "/images/dummyProfile.jpg",
};

const dummyPostData = {
  postId: "dummyPostId",
  postContent: "This is a dummy post content.",
  linkedinPostUrl: "https://linkedin.com/dummy-post",
  postTimeAgo: "2 days ago",
  likes: 123,
  comments: 45,
  postType: "TEXT",
  originalUrl: "",
};

const dummyAnalyticsData = {
  reaction: {
    LIKE: { count: 30 },
    EMPATHY: { count: 20 },
    PRAISE: { count: 15 },
    APPRECIATION: { count: 10 },
    INTEREST: { count: 5 },
    ENTERTAINMENT: { count: 2 },
  },
  comments: [
    {
      name: "Alice",
      time: "1 day ago",
      comment: "Great post!",
      img: "/images/commenter1.jpg",
      media: null,
      commentsOnComment: [],
    },
  ],
};


function PostAnalytics() {
  const { isDark } = useSelector((state) => state.common);
  const { userData } = useSelector((state) => state.user);  
  console.log(userData.linkedinId);
  useEffect(() => {
    dispatch(postAnalytics(postId));
  }, []);

  const {
    likesByCompany,
    likesByOccupation,
    likesByRegion,
    commentsByCompany,
    commentsByOccupation,
    commentsByRegion,
    postPost,
  } = useSelector((s) => s.analytics);
  console.log(postPost);
  const calculateRegionData = (likesByRegion, commentsByRegion) => {
    const regionData = {};

    // Check if likesByRegion and commentsByRegion are defined
    if (likesByRegion) {
      Object.entries(likesByRegion).forEach(([region, likes]) => {
        regionData[region] = (regionData[region] || 0) + likes;
      });
    }

    if (commentsByRegion) {
      Object.entries(commentsByRegion).forEach(([region, comments]) => {
        regionData[region] = (regionData[region] || 0) + comments;
      });
    }

    // Calculate total count
    const totalCount = Object.values(regionData).reduce(
      (sum, count) => sum + count,
      0
    );

    // Calculate percentage for each region and format the data
    const data = Object.entries(regionData).map(([region, count]) => ({
      name: region,
      value: Math.round((count / totalCount) * 100),
    }));

    return data;
  };

  // Likes and comments by region
const likesByRegion1 = {
  "North America": 1200,
  Europe: 1500,
  Asia: 2100,
  Africa: 800,
  "South America": 950,
};

const commentsByRegion1 = {
  "North America": 300,
  Europe: 450,
  Asia: 500,
  Africa: 200,
  "South America": 250,
};

// Likes and comments by occupation
const likesByOccupation1 = {
  Engineers: 1800,
  Teachers: 1400,
  "Health Workers": 1600,
  "Business Professionals": 1900,
  Students: 1100,
};

const commentsByOccupation1 = {
  Engineers: 400,
  Teachers: 350,
  "Health Workers": 370,
  "Business Professionals": 420,
  Students: 280,
};


  const data1 = calculateRegionData(likesByRegion1, commentsByRegion1);
  const data2 = calculateRegionData(likesByOccupation1, commentsByOccupation1);
  // const data2 = [
  //   { name: "North America", occupation: "Software Engineer", value: 45 },
  //   { name: "Europe", occupation: "Data Scientist", value: 30 },
  //   { name: "Asia", occupation: "Product Manager", value: 25 },
  //   { name: "South America", occupation: "UX Designer", value: 20 },
  //   { name: "Africa", occupation: "Cybersecurity Analyst", value: 15 },  
  // ]
  // const data3 = calculateRegionData(likesByCompany, commentsByCompany);
  const data3 = [
    { name: "Google", value: 30 },
    { name: "MicroSoft", value: 25 },
    { name: "wallmart", value: 25 }
  ];
  
  const navigate = useNavigate();
  const location = useLocation();
  const postId = location.state?.postId;
  console.log(postId);
  const { post } = useSelector((s) => s.analytics);
  console.log(post);
  const [activePost, setActivePost] = useState();
  useEffect(() => {
    if (Array.isArray(post) && post.length > 0) {
      const obj = post.find((p) => p.postId === postId);
      setActivePost(obj);
    }
  }, [postId, post]);
  console.log(postId);

  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userProfileData(userData.linkedinId));
    dispatch(reactionSummary(postId));
    dispatch(allComments(postId));
  }, []);
  const analyticsData = useSelector((s) => s.analytics);
  console.log("analyticsData");
  console.log(analyticsData);
  console.log("analyticsData");
  const image = "/images/pp.jpeg";
  return (
    <>
      <div className="flex flex-col gap-12 px-4 py-4 mb-24 ">
        {/* <span className={`${isDark?'bg-white':'bg-[#2D2D2D]'} w-[196px] text-center p-2 rounded-lg cursor-pointer`} onClick={()=>navigate('/analytics')}>&larr; Go back to Analytics</span> */}
        <div className="flex justify-evenly items-start max-md:flex-col max-md:mb-2">
          <div className="bg-white p-3 rounded-lg w-[38%] max-md:w-[100%] max-md:mb-3 flex flex-col gap-3">
            <div className="flex justify-between items-start">
              <div className="flex items-start gap-3">
                {analyticsData ? (
                  <img
                    src={
                      analyticsData.linkedinProfile
                        ? analyticsData.linkedinProfile
                        : image
                    }
                    className="w-12 h-12 rounded-full"
                  ></img>
                ) : (
                  ""
                )}
                <div className="flex flex-col gap-1">
                  {analyticsData ? (
                    <p style={{ color: "black" }} className="font-medium">
                      {analyticsData.firstname} {analyticsData.lastname}
                    </p>
                  ) : (
                    ""
                  )}
                  {analyticsData ? (
                    <p style={{ color: "gray" }} className="text-sm">
                      {analyticsData.currentPosition}
                    </p>
                  ) : (
                    ""
                  )}
                  {activePost ? (
                    <p
                      style={{ color: "gray" }}
                      className="text-sm flex items-center gap-2"
                    >
                      {activePost.postTimeAgo}{" "}
                      <i className="fa-solid fa-earth-europe"></i>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <img src="/images/linkedInIcon.svg"></img>
            </div>
            <div className="flex flex-col justify-center gap-1">
              {activePost ? (
                <>
                  <p style={{ color: "black" }}>
                    {activePost.postContent.length > 500
                      ? `${activePost.postContent.slice(0, 500)}...`
                      : activePost.postContent}
                    {activePost.postContent.length > 500 ? (
                      <a href={activePost.linkedinPostUrl} target="_blank">
                        <span
                          style={{ color: "#6f9cde" }}
                          className="cursor-pointer"
                        >
                          read more
                        </span>
                      </a>
                    ) : (
                      ""
                    )}
                  </p>
                  {/* Add URL preview for post content */}
                  {extractUrl(activePost.postContent) && (
                    <UrlPreview url={extractUrl(activePost.postContent)} />
                  )}
                </>
              ) : (
                ""
              )}
              <div className="flex justify-center items-center">
                {postPost && postPost.postType == "IMAGE" ? (
                  <img
                    src={`https://growero-staging.s3.ap-south-1.amazonaws.com/uploads/${postPost.originalUrl}`}
                    className="w-96"
                  ></img>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-blue-500">
              {postPost && postPost.postType == "ARTICLE" ? (
                <UrlPreview url={postPost.originalUrl} />
              ) : (
                ""
              )}
            </div>
            <div className="flex gap-4">
              <div className="flex gap-1 items-center">
                <img src="/images/likeBlack.svg"></img>
                {activePost ? (
                  <p style={{ color: "black" }}>{activePost.likes}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="flex gap-1 items-center">
                <img src="/images/commentBlack.svg "></img>
                {activePost ? (
                  <p style={{ color: "black" }}>{activePost.comments}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="flex max-md:flex-row gap-12 px-4 py-4 mb-2">
          <div
            className={`${
              isDark ? "bg-white" : "bg-[#2D2D2D]"
            }  flex flex-col justify-center items-center p-3 gap-4  max-md:w-full rounded-lg`}
          >
            <img src="/images/linkedInLikes.png" className="w-[94px]" />
            <span className="flex gap-2">
              {activePost ? (
                <p className="text-[15px] font-medium">
                  Likes : 86
                </p>
              ) : (
                ""
              )}
            </span>
          </div>
          <div
            className={`${
              isDark ? "bg-white" : "bg-[#2D2D2D]"
            }  flex flex-col justify-center items-center p-3 gap-4 max-md:w-full rounded-lg`}
          >
            <img src="/images/CommentImg.png" className="w-[94px]" />
            <span className="flex gap-2">
              {activePost ? (
                <p className="text-[15px] font-medium">
                  Comments : 5
                </p>
              ) : (
                ""
              )}
            </span>
          </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-full">
            <p>Company</p>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col rounded-lg gap-1 `}
            >
              {/* <p style={{color:'gray'}}>48 people from Amazon viewed your profile</p>
<p style={{color:'gray'}}>Along with people who have the title Marketing Specialist and Salesperson</p> */}
              {data3.length ? (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={data3}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, value }) => `${name} (${value}%)`}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data3.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <p className="p-20 text-center">No data for company</p>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-2 w-full">
            <p>Occupation</p>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col rounded-lg gap-1`}
            >
              {/* <p style={{color:'gray'}}>1,094 people who have the title Founder viewed your post</p>
<p style={{color:'gray'}}>Along with people from Deloitte and Flipkart</p> */}
              {data2.length ? (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={data2}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, value }) => `${name} (${value}%)`}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data2.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <p className="p-20 text-center">No data for Occupation</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-col gap-2 w-full">
            <p>Region</p>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col rounded-lg gap-1`}
            >
              {/* <p style={{color:'gray'}}>853 people viewed your post from Copenhagen Metropolitan Area</p>
<p style={{color:'gray'}}>Along with people from London Area, United Kingdom and Greater Bengaluru Area</p> */}
              {data1.length ? (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={data1}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, value }) => `${name} (${value}%)`}
                      outerRadius={120}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {data1.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Legend />
                  </PieChart>
                </ResponsiveContainer>
              ) : (
                <p className="p-20 text-center">No data for region</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-4 w-full max-md:flex-col">
          <div className="flex flex-col gap-2 w-full">
            <p>Comments</p>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col rounded-lg gap-4 w-full min-h-56`}
            >
              {analyticsData.comments && analyticsData.comments.length > 0 ? (
                analyticsData.comments.map((comment) => (
                  <CommentComponent key={comment.name} comment={comment} />
                ))
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-center">No comment on this post yet</p>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p>Reactions</p>
            <div
              className={`${
                isDark ? "bg-white" : "bg-[#2D2D2D]"
              } p-3 flex flex-col gap-8 justify-center items-center rounded-lg min-h-[300px] max-md:min-h-[150px]`}
            >
              {analyticsData.reaction &&
              typeof analyticsData.reaction === "object" ? (
                <>
                  <div className="flex gap-16 max-lg:gap-3">
                    <p className="flex items-center gap-4">
                      <img
                        src="/images/linkedInLikes.png"
                        className="w-16 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.LIKE
                        ? analyticsData.reaction.LIKE.count
                        : 0}
                    </p>
                    <p className="flex items-center gap-4">
                      <img
                        src="/images/Love.png"
                        className="w-16 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.EMPATHY
                        ? analyticsData.reaction.EMPATHY.count
                        : 0}
                    </p>
                    <p className="flex items-center gap-4">
                      <img
                        src="/images/Celebrate.png"
                        className="w-16 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.PRAISE
                        ? analyticsData.reaction.PRAISE.count
                        : 0}
                    </p>
                  </div>
                  <div className="flex gap-16 max-lg:gap-3">
                    <p className="flex items-center gap-4">
                      <img
                        src="/images/Support.png"
                        className="w-14 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.APPRECIATION
                        ? analyticsData.reaction.APPRECIATION.count
                        : 0}
                    </p>
                    <p className="flex items-center gap-4 ">
                      <img
                        src="/images/Insightful.png"
                        className="w-16 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.INTEREST
                        ? analyticsData.reaction.INTEREST.count
                        : 0}
                    </p>
                    <p className="flex items-center gap-4 ">
                      <img
                        src="/images/funny.png"
                        className="w-16 max-md:w-10"
                      ></img>
                      {analyticsData.reaction.ENTERTAINMENT
                        ? analyticsData.reaction.ENTERTAINMENT.count
                        : 0}
                    </p>
                  </div>
                </>
              ) : (
                <p>No reaction data available</p>
              )}
            </div>
          </div>
        </div>
        </div>
    </>
  );
}

import axios from "axios";

const image = "/images/pp.jpeg";
const UrlPreview = ({ url }) => {
  const [previewData, setPreviewData] = useState(null);

  useEffect(() => {
    const fetchMetaData = async () => {
      try {
        const response = await axios.post(`${apibase}/api/get-meta-data`, {
          url,
        });
        setPreviewData(response.data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
        setPreviewData({
          data: { ogTitle: url, ogImage: [{ url: "", type: "png" }] },
        });
      }
    };

    fetchMetaData();
  }, [url]);

  if (!previewData) return null;

  return (
    <div
      className="card mt-2"
      style={{ margin: "10px 0", position: "relative" }}
    >
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "black" }}
      >
        {previewData.data &&
          previewData.data.ogImage &&
          previewData.data.ogImage[0] &&
          previewData.data.ogImage[0].url && (
            <img
              src={previewData.data.ogImage[0].url}
              className="card-img-top"
              style={{ margin: "5% 5% 5% 5%", height: "20%", width: "90%" }}
              alt="Preview Image"
            />
          )}
        <div
          style={{ backgroundColor: "rgb(237,243,248)" }}
          className="card-body"
        >
          <h6
            style={{ fontWeight: "bold", marginLeft: "16px" }}
            className="card-title text-dark ml-10"
          >
            {previewData.data.ogTitle}
          </h6>
          <p className="card-text" style={{ color: "black" }}>
            {previewData.data.ogDescription}
          </p>
        </div>
      </a>
    </div>
  );
};
const extractUrl = (text) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const match = text.match(urlRegex);
  return match ? match[0] : null;
};

const defaultAvatar = "https://dev.growero.io/images/Icons/avatar.jpg";
const CommentComponent = ({ comment, defaultImage = defaultAvatar }) => {
  // const extractUrl = (text) => {
  //   const urlRegex = /(https?:\/\/[^\s]+)/g;
  //   const match = text.match(urlRegex);
  //   return match ? match[0] : null;
  // };

  const url = extractUrl(comment.comment);

  return (
    <div
      className="flex gap-3 items-start"
      key={`${comment.name}-${comment.time}`}
    >
      <img
        src={comment.img ? comment.img : defaultImage}
        className="w-10 h-10 rounded-full"
        alt=""
      />
      <div className="flex flex-col gap-1 w-full">
        <div className="p-2 rounded-lg w-full">
          <span className="text-sm flex gap-2">
            {comment.name}
            <p style={{ color: "gray" }}>{comment.time}</p>
          </span>
          <span className="text-sm">{comment.comment}</span>

          {url && <UrlPreview url={url} />}

          {comment.media && (
            <div className="mt-2">
              {comment.media.type === "image" && (
                <img
                  src={comment.media.url}
                  alt="Attached image"
                  className="max-w-full h-auto rounded"
                />
              )}
              {comment.media.type === "file" && (
                <a
                  href={comment.media.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  <i className="fas fa-file mr-2"></i>
                  {comment.media.filename || "Attached file"}
                </a>
              )}
            </div>
          )}
        </div>

        {comment.commentsOnComment &&
          comment.commentsOnComment.map((reply) => (
            <CommentComponent
              key={`${reply.name}-${reply.time}`}
              comment={reply}
              defaultImage={defaultImage}
            />
          ))}
      </div>
    </div>
  );
};
export default PostAnalytics;
