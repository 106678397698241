import axios from "axios";
import { axiosInstance } from "../../../api/API";
export const fetchAnalyticsData = (userId, startDate, endDate) => async (dispatch) => {
  try {
    console.log('Fetching analytics data for user:', userId);
    console.log('Timeframe:', `Start Date: ${startDate}, End Date: ${endDate}`);

    // Make concurrent API calls
    const [
      followersGraphResponse,
      summaryResponse,
      postsResponse,
      engagementResponse,
      dailyAnalyticsResponse,
      followersResponse
    ] = await Promise.all([
      axiosInstance.get(`/analytics/followers/user/${userId}/timeframe?startDate=${startDate}&endDate=${endDate}`),
      axiosInstance.get(`/analytics/graphs/engagement-data/${userId}/?startDate=${startDate}&endDate=${endDate}`),
      axiosInstance.get(`/post-analytics/user/${userId}/posts/data/timeframe?startTime=${startDate}&endTime=${endDate}`),
      axiosInstance.get(`/analytics/engagements/user/${userId}/timeframe?startDate=${startDate}&endDate=${endDate}`),
      axiosInstance.get(`/post-analytics/posts/daily-analytics/timeframe?userId=${userId}&startTime=${startDate}&endTime=${endDate}`),
      axiosInstance.get(`/analytics/followers/user/${userId}/timeframe?startDate=${startDate}&endDate=${endDate}`)
    ]);

    console.log('API call responses received.');
    const sampleSummaryResponse = {
      data: [
        { label: "Impressions", number: 328 },
        { label: "Total Reactions",number:100 }, // Missing number
        { label: "Comments", number: 100 },
        { label: "Total Engagements", number: 37 },
        { label: "Shares", number: 78 },
        { label: "Engagement Rate", number: "1.83%" }
      ]
    };

    // Log the details of each response
    console.log('Followers Graph Response:', followersGraphResponse.data);
    console.log('Summary Response:', summaryResponse.data);
    console.log('Posts Response:', postsResponse.data);
    console.log('Engagement Response:', engagementResponse.data);
    console.log('Daily Analytics Response:', dailyAnalyticsResponse.data);
    console.log('Followers Response:', followersResponse.data);

    // Dispatch the received data to the Redux store
    dispatch({ 
      type: "SET_ANALYTICS_DATA", 
      payload: {
        followersGraph: followersGraphResponse.data,
        summary: sampleSummaryResponse.data,
        posts: postsResponse.data,
        engagement: engagementResponse.data,
        dailyAnalytics: dailyAnalyticsResponse.data
      }
    });

    console.log('Dispatched analytics data to Redux store.');

    // Update other relevant data
    dispatch(totalLikesAndComments(userId));
    dispatch(allPosts(userId));
    dispatch(impressionTotal(userId, startDate, endDate));

    console.log('Updated additional relevant data.');
    
  } catch (error) {
    console.error("Error fetching analytics data:", error);
    dispatch({ type: "SET_ANALYTICS_ERROR", payload: error.message });
  }
};
export const totalLikesAndComments = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/detailed-list-by-userId/${id}`)
    .then((res) => {
      dispatch({ type: "TOTAL_LC", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "TOTAL_LC", payload: err });
    });
};

export const userProfileData = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/get-linkedin-profile/${id}`)
    .then((res) => {
      dispatch({ type: "PROFILE", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "PROFILE", payload: err });
    });
};

export const reactionSummary = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`/post-analytics/post/${postId}/reaction-summaries`)
    .then((res) => {
      dispatch({ type: "REACTION", payload: res });
      console.log(postId);
    })
    .catch((err) => {
      dispatch({ type: "REACTION", payload: err });
      console.log(postId);
    });
};

export const dailyPostTracker = (id) => async (dispatch) => {
  await axiosInstance
    .get(`daily-post-tracker/count/${id}`)
    .then((res) => {
      dispatch({ type: "POST_TRACKER", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "POST_TRACKER", payload: err });
    });
};

export const allPosts = (id) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/get-all-post-analytics-by-userId/${id}`)
    .then((res) => {
      dispatch({ type: "ALL_POST", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "ALL_POST", payload: err });
    });
};

export const allComments = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/post/${postId}/get-all-comments`)
    .then((res) => {
      dispatch({ type: "ALL_COMMENTS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "ALL_COMMENTS", payload: err });
    });
};

export const impressionTotal =
  (userId, startTime, endTime) => async (dispatch) => {
    console.log("startTime,endTime")
    console.log(userId)
    console.log("startTime,endTime")
    await axiosInstance
      .get(
        `analytics/engagements/user/${userId}/timeframe?startDate=${startTime}&endDate=${endTime}`
      )
      .then((res) => {
        console.log("res");
        console.log(res);
        console.log("respi");
        dispatch({ type: "IMPRESSION", payload: res });
        console.log(userId, startTime, endTime);
      })
      .catch((err) => {
        dispatch({ type: "IMPRESSION", payload: err });
      });
  };

export const postAnalytics = (postId) => async (dispatch) => {
  await axiosInstance
    .get(`post-analytics/post/${postId}`)
    .then((res) => {
      dispatch({ type: "POST_ANALYTICS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "POST_ANALYTICS", payload: err });
    });
};

export const getTeamMembers = (teamId) => async (dispatch) => {
  await axiosInstance
    .get(`invite/get-team-members-by-teamid/${teamId}`)
    .then((res) => {
      dispatch({ type: "TEAM_MEMBERS", payload: res });
    })
    .catch((err) => {
      dispatch({ type: "TEAM_MEMBERS", payload: err });
    });
};

export const setUserId = (data) => {
  return (dispatch) => {
    dispatch({ type: "SET_USER_ID", payload: data });
  };
};


export const setDateRange = (dateRange) => ({
  type: "SET_DATE_RANGE",
  payload: dateRange
});