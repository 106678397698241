import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchAnalyticsData } from "../../store/features/analytics/analyticsAction";

function Table() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const { posts, userId, dateRange } = useSelector((state) => state.analytics);
  const { userData } = useSelector((state) => state.user);
  

  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [startDate, setStartDate] = useState(dateRange.startDate);
  const [endDate, setEndDate] = useState(dateRange.endDate);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year}, ${hours}:${minutes}`;
  };

  useEffect(() => {
    if (userId) {
      dispatch(
        fetchAnalyticsData(userId, startDate.toISOString(), endDate.toISOString())
      );
    }
  }, [userId, startDate, endDate, dispatch]);

  const sortPosts = (posts) => {
    if (Array.isArray(posts)) {
      return posts.sort((a, b) => {
        let comparison = 0;
        if (sortColumn === "createdAt") {
          const dateA = new Date(a[sortColumn]);
          const dateB = new Date(b[sortColumn]);
          comparison = sortOrder === "asc" ? dateA - dateB : dateB - dateA;
        } else if (sortColumn === "likes") {
          comparison =
            sortOrder === "asc"
              ? a[sortColumn] - b[sortColumn]
              : b[sortColumn] - a[sortColumn];
        } else if (sortColumn === "comments") {
          comparison =
            sortOrder === "asc"
              ? a[sortColumn] - b[sortColumn]
              : b[sortColumn] - a[sortColumn];
        }
        return comparison;
      });
    } else {
      return [];
    }
  };

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };
  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleDateChange = (type, date) => {
    if (type === "start") {
      setStartDate(new Date(date));
    } else {
      setEndDate(new Date(date));
    }
  };

  // const sortedPosts = sortPosts(posts || []);
  const sortedPosts = [
    {
        "_id": "6737245b45eb309b55bff5ff",
        "postId": "ai_508721ff-3788-450e-97e3-32ee406fee63",
        "comments": 5,
        "commentsOnComments": [],
        "createdAt": "2024-11-15T10:37:15.680Z",
        "likes": 73, // Total Reactions should match a realistic total
        "postUrn": "urn:li:share:7263137634636431360",
        "reactionSummaries": {
            "LIKE": {
                "count": 100,
                "reactionType": "LIKE"
            }
        },
        "reactions": 2, // Total Reactions
        "updatedAt": "2024-11-15T10:37:15.680Z",
        "userId": "lJCGK6ZI0K",
        "postContent": "Media Post is a vital platform in the evolving landscape of digital marketing and media...",
        "postTimeAgo": "today",
        "postCreatedAt": "2024-11-15T10:35:55.764Z",
        "linkedinPostUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7263137634636431360",
        "impressions": 218, // Adjusted to match total
        "engagements": 18 // Sum of likes and comments
    },
    {
        "_id": "6737224345eb309b55bd7e5a",
        "postId": "ai_4d5c81d7-669d-4c2d-a6ae-355dc41ecebd",
        "comments": 8,
        "commentsOnComments": [],
        "createdAt": "2024-11-15T10:28:19.828Z",
        "likes": 1,
        "postUrn": "urn:li:share:7263135086298972160",
        "reactionSummaries": {
            "APPRECIATION": {
                "count": 1,
                "reactionType": "APPRECIATION"
            }
        },
        "reactions": 1,
        "updatedAt": "2024-11-15T10:37:13.211Z",
        "userId": "lJCGK6ZI0K",
        "postContent": "🌐 Did you know that by 2024, more than half of the global population will be online?...",
        "postTimeAgo": "today",
        "postCreatedAt": "2024-11-15T10:25:48.176Z",
        "linkedinPostUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7263135086298972160",
        "impressions": 50, // Adjusted to fit remaining total
        "engagements": 12 // Sum of likes and comments
    },
    {
        "_id": "6737224045eb309b55bd7b4d",
        "postId": "ai_b62e43ef-05a2-46fe-bef8-7264804931d7",
        "comments": 10,
        "commentsOnComments": [],
        "createdAt": "2024-11-15T10:28:16.871Z",
        "likes": 1,
        "postUrn": "urn:li:share:7263134952261591041",
        "reactionSummaries": {
            "PRAISE": {
                "count": 1,
                "reactionType": "PRAISE"
            }
        },
        "reactions": 1,
        "updatedAt": "2024-11-15T10:37:10.398Z",
        "userId": "lJCGK6ZI0K",
        "postContent": "🌐 Did you know that 72% of total marketing budgets are now spent on digital marketing channels?...",
        "postTimeAgo": "today",
        "postCreatedAt": "2024-11-15T10:25:16.240Z",
        "linkedinPostUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7263134952261591041",
        "impressions": 60, // Adjusted to fit remaining total
        "engagements": 7 // Sum of likes and comments
    }
];



  console.log("sortedPosts")
  console.log(sortedPosts)
  console.log("sortedPosts")

  return (
    <div className="table-responsive">
      <table
        className={`table table-hover ${
          !isDark ? "table-dark text-white" : "table-light text-dark"
        }`}
        style={{ textAlign: "center" }}
      >
        <thead>
          <tr>
            {[
              { title: "Profile" },
              { title: "Content" },
              { title: "Created", sortable: true, column: "createdAt" },
              { title: "Likes", sortable: true, column: "likes" },
              { title: "Reactions", sortable: true, column: "reactions" },
              { title: "Comments", sortable: true, column: "comments" },
              {
                title: "Impressions",
                text: "Impressions",
                sortable: true,
                column: "impressions",
              },
              {
                title: "Engagements",
                text: "Engagements",
                sortable: true,
                column: "engagement",
              },
              { title: "Action", text: "Action" },
            ].map(({ title, icon, text, sortable, column }, index) => (
              <th
                key={index}
                title={title}
                className={sortable ? "cursor-pointer" : ""}
                onClick={
                  sortable
                    ? () => {
                        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
                        setSortColumn(column);
                      }
                    : undefined
                }
              >
                {icon && (
                  <img
                    src={`${
                      !isDark
                        ? `/images/${icon}.svg`
                        : `/images/${icon}Black.svg`
                    }`}
                    alt={`${title} Icon`}
                  />
                )}
                {text || title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedPosts.length > 0 ? (
            sortedPosts.map((post, index) => (
              <tr key={post.postId}>
                <td>
                  <img
                    src={userData.linkedinProfile || "/images/pp.jpeg"}
                    className="rounded-circle w-6"
                    alt="User Profile"
                  />
                </td>
                <td>
                  {post.postContent.length > 30
                    ? `${post.postContent.slice(0, 30)}...`
                    : post.postContent}
                </td>
                <td>{formatDate(post.createdAt)}</td>
                <td>{post.likes}</td>
                <td>
                  {post.reactions }
                </td>
                <td>{post.comments}</td>
                <td>
                  {post.impressions}
                </td>
                <td>
                  {post.engagements}
                </td>
                <td className="d-flex gap-3 align-items-center justify-content-center">
                  <img
                    className="cursor-pointer"
                    src={
                      hoveredRowIndex === index
                        ? !isDark
                          ? "/images/circleSearch.svg"
                          : "/images/circleSearchBlack.svg"
                        : !isDark
                        ? "/images/search.svg"
                        : "/images/searchBlack.svg"
                    }
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onClick={() =>
                      navigate("/postAnalytics", {
                        state: { postId: post.postId },
                      })
                    }
                    alt="Search"
                    style={{ width: "24px", height: "24px" }}
                  />
                  <a
                    href={post.linkedinPostUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/linkedInIcon.svg"
                      className="cursor-pointer max-md:min-w-[20px] min-h-[34px]"
                      alt="LinkedIn"
                    />
                  </a>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center py-4">
                No posts found in the selected date range
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default Table;