import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

function TopInteractors() {
  const analyticsData = useSelector((s) => s.analytics);
  const [active, setActive] = useState(0);
  const [activeUser, setActiveUser] = useState();
  const [filteredInteractions, setFilteredInteractions] = useState([]);
  const image = "/images/pp.jpeg";
  const { isDark } = useSelector((state) => state.common);

  const handleClick = (index, userId) => {
    setActive(index);
    const obj = filteredInteractions.find((s) => s.userId === userId);
    setActiveUser(obj);
    console.log(obj);
  };

  useEffect(() => {
    const newFilteredInteractions = analyticsData.interactions
      ? analyticsData.interactions.filter((interaction) => interaction.name)
      : [];
    setFilteredInteractions(newFilteredInteractions);

    if (newFilteredInteractions.length > 0) {
      setActiveUser(newFilteredInteractions[0]);
    } else {
      setActiveUser(null); // Set activeUser to null if there are no interactions
    }
    setFilteredInteractions([
      {
        userId: 1,
        name: "John Doe",
        designation: "Software Engineer",
        profilePicture: "https://xsgames.co/randomusers/assets/avatars/male/46.jpg",
        totalLikesOnAllPosts: 120,
        totalCommentsOnAllPosts: 45,
      },
      {
        userId: 2,
        name: "Jane Smith",
        designation: "Product Manager",
        profilePicture: "https://pbs.twimg.com/media/FjU2lkcWYAgNG6d.jpg",
        totalLikesOnAllPosts: 200,
        totalCommentsOnAllPosts: 30,
      },
      {
        userId: 3,
        name: "Alice Johnson",
        designation: "Data Scientist",
        profilePicture: "https://i.pinimg.com/236x/ce/d8/4a/ced84a67302c60bd1abaaf9314064433.jpg",
        totalLikesOnAllPosts: 150,
        totalCommentsOnAllPosts: 40,
      },
      {
        userId: 4,
        name: "Bob Williams",
        designation: "UI/UX Designer",
        profilePicture: "https://media.istockphoto.com/id/1398385367/photo/happy-millennial-business-woman-in-glasses-posing-with-hands-folded.jpg?s=612x612&w=0&k=20&c=Wd2vTDd6tJ5SeEY-aw0WL0bew8TAkyUGVvNQRj3oJFw=",
        totalLikesOnAllPosts: 85,
        totalCommentsOnAllPosts: 20,
      },
      {
        userId: 5,
        name: "Michael Brown",
        designation: "DevOps Engineer",
        profilePicture: "https://media.istockphoto.com/id/1317804578/photo/one-businesswoman-headshot-smiling-at-the-camera.jpg?s=612x612&w=0&k=20&c=EqR2Lffp4tkIYzpqYh8aYIPRr-gmZliRHRxcQC5yylY=",
        totalLikesOnAllPosts: 95,
        totalCommentsOnAllPosts: 28,
      },
      {
        userId: 6,
        name: "Emily Davis",
        designation: "Marketing Specialist",
        profilePicture: "https://img.freepik.com/free-vector/profile-picture-template-design_742173-22027.jpg",
        totalLikesOnAllPosts: 300,
        totalCommentsOnAllPosts: 60,
      }
    ]);
      
  }, [analyticsData.interactions]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-nowrap items-center">
        {filteredInteractions.map((s, index) => (
          <img
            src={s.profilePicture ? s.profilePicture : image}
            key={index}
            className={`cursor-pointer ${
              active === index
                ? isDark
                  ? "border-[3px] border-solid border-transparent w-28 h-28 bg-gradient-to-t from-[#8A51FF] via-[#39B2FF] to-[#39B2FF] bg-clip-border"
                  : "border-white w-28 h-28"
                : isDark
                ? "border-white w-20 h-20"
                : "border-[#2D2D2D] w-20 h-20"
            } ${
              index !== 0 ? "-ml-[20px] z-[index]" : "-ml-0"
            } w-20 h-20 border-4 relative`}
            style={{
              borderRadius: "50%",
              zIndex: active === index ? "1" : "0",
            }}
            onClick={() => handleClick(index, s.userId)}
            alt="Profile"
          />
        ))}
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-1">
          {activeUser ? (
            <>
              <p className="text-lg">{activeUser.name}</p>
              <p className="text-base" style={{ color: "#939BA5" }}>
                {activeUser.designation}
              </p>
            </>
          ) : (
            <div className="flex justify-center items-center mb-4">
              <p>No Interactions</p>
            </div> // Show a message if there are no interactions
          )}
        </div>
        <div className="flex gap-4">
          {activeUser ? (
            <div className="flex items-center gap-2">
              <p>{activeUser.totalLikesOnAllPosts}</p>
              <img src="/images/likeIcon.svg" alt="Like" />
            </div>
          ) : null}
          {activeUser ? (
            <div className="flex items-center gap-2">
              <p>{activeUser.totalCommentsOnAllPosts}</p>
              <img src="/images/commentIcon.svg" alt="Comment" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default TopInteractors;
