import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import './DemographicsChart.css'; 
import { useDispatch, useSelector } from "react-redux";
import { getApi, postApi } from '../../api/methods'
import { setDateRange, fetchAnalyticsData } from '../../store/features/analytics/analyticsAction';
import { BASE_URL } from '../../api/API';

// Define color palettes with gradient stops for slices
const GRADIENT_COLORS = [
  ['#007bff', '#6610f2'],
  ['#0056b3', '#4e0bc2'],
  ['#004085', '#390d8a'],
  ['#003366', '#300774'],
  ['#1a237e', '#5c6bc0'], // Indigo Gradient
  ['#3f51b5', '#283593'], // Deep Blue Gradient
  ['#34495e', '#2c3e50'], // Midnight Blue Gradient
  ['#212529', '#343a40'], // Dark Gray Gradient
  ['#6a1b9a', '#8e24aa'], // Purple Gradient
];


const DemographicsChart = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const { isDark } = useSelector((state) => state.common);
  const { impression, summary, followersGraph, userId, dateRange, engagement, dailyAnalytics } = useSelector((state) => state.analytics);
  
  const [selectedCategory, setSelectedCategory] = useState('Job titles');

  useEffect(() => {
    if (userId && dateRange) {
      const formattedStartDate = dateRange.startDate.toISOString().slice(0, 10);
      const formattedEndDate = dateRange.endDate.toISOString().slice(0, 10);
      dispatch(fetchAnalyticsData(userId, formattedStartDate, formattedEndDate));
    }
  }, [userId, dateRange, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}analytics/demographics/user/S0vwyELISB`);
        const filteredData = filterDataByCategory(response.data, selectedCategory);
        setData(filteredData.length ? filteredData : getDummyData());
      } catch (error) {
        console.error('Error fetching data:', error);
        setData(getDummyData());
      }
    };
    fetchData();
  }, [selectedCategory]);

  const filterDataByCategory = (apiData, category) => {
    return apiData
      .filter(item => item.category === category)
      .map((item, index) => ({
        name: `${item.value}`,
        value: item.percentage,
        fill: `url(#gradient-${index % GRADIENT_COLORS.length})`
      }));
  };

  const getDummyData = () => [
    { value: 30, fill: `url(#gradient-0)`, name: 'Software Engineer' },
    {  value: 20, fill: `url(#gradient-1)`, name: 'Product Designer' },
    {  value: 50, fill: `url(#gradient-2)`, name: 'Project Manager' },
    {  value: 45, fill: `url(#gradient-3)`, name: 'Data Analyst' },
    {  value: 25, fill: `url(#gradient-4)`, name: 'Marketing Specialist' }
  ];
  
  
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const renderCustomizedLabel = ({ percent, name }) => {
    return `${name} (${(percent * 100).toFixed(0)}%)`;
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <div
        className={`flex w-full items-center px-4 py-2 ${!isDark ? 'text-white bg-black-900' : 'text-black bg-gray-100'}`}
        style={{ borderRadius: '8px' }}
      >
        {/* Use a div to occupy space evenly on either side */}
        <div className="flex-1"></div>
  
        {/* Center the title by allowing space on both sides */}
        <h3 className={`text-2xl font-bold ${!isDark ? 'text-white' : 'text-black'}`}>
          Demographics Analysis
        </h3>
  
        {/* Use flex-grow to push the select element to the far right */}
        <div className="flex-1 text-right">
          <select
            onChange={handleCategoryChange}
            value={selectedCategory}
            className={`p-2 border rounded ${!isDark ? 'bg-gray-700 text-white' : 'bg-white text-black'}`}
          >
            <option value="Job titles">Job Titles</option>
            <option value="Locations">Locations</option>
            <option value="Industries">Industries</option>
            <option value="Seniority">Seniority</option>
            <option value="Company size">Company Size</option>
            <option value="Companies">Companies</option>
          </select>
        </div>
      </div>
  
      <div className={`chart-box shadow-md p-4 w-full ${!isDark ? 'bg-[#2D2D2D] text-white' : 'bg-gray text-black'}`} style={{ borderRadius: '8px' }}>
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <defs>
              {/* Define gradients for each slice based on index */}
              {GRADIENT_COLORS.map((color, index) => (
                <linearGradient key={index} id={`gradient-${index}`} x1="0" y1="0" x2="1" y2="1">
                  <stop offset="0%" stopColor={color[0]} />
                  <stop offset="100%" stopColor={color[1]} />
                </linearGradient>
              ))}
            </defs>
            <Pie
              data={data}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={150}
              label={renderCustomizedLabel}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.fill} />
              ))}
            </Pie>
            {/* <Tooltip /> */}
            <Legend verticalAlign="bottom" height={36} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
  
};

export default DemographicsChart;
