import React, { useState, useEffect } from 'react';
import { getApi } from '../../../api/methods';
import './style.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Subscription = (props) => {
  const navigate = useNavigate();
  const planarr = ['Monthly', 'Yearly'];
  const [getsubscription, setSubscription] = useState([]);
  const [activePlan, setActivePlan] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(planarr[activePlan]);
  const { isDark } = useSelector(state => state.common);

  const getSubList = () => {
    getApi('subscription/viewsubscription')
      .then((res) => {
        setSubscription(res.data);
      })
      .catch((err) => {
        console.error('Error fetching subscription list:', err);
      });
  };

  const handleProceed = (item) => {
    const data = item.cost.find((ev) => ev.durationType === selectedPlan);
    const cartData = {
      ...item,
      cost: data.amount,
      planDuration: selectedPlan,
    };

    localStorage.setItem('cartdata', JSON.stringify(cartData));
    props.setIsShowSubs(false);
    navigate('/order-review');
  };

  const getMonthlyPlan = () => {
    return getsubscription?.map((item, index) => {
      const isCenterCard = index === Math.floor(getsubscription?.length / 2);
      let planDescription;
      let planCredits = '';
      let planAccounts = '';
      let planTeamMembers = '';

      switch (item.planType) {
        case 'Pro':
          planDescription = 'Begin producing top-performing content daily while spending 80% less time.';
          planCredits = '300 Credits';
          planAccounts = '1 Account';
          planTeamMembers = '1 User';
          break;
        case 'Creator':
          planDescription = 'Harness the potential of AI-crafted posts, and team insights and organic engagement.';
          planCredits = '1500 Credits';
          planAccounts = '5 Accounts';
          planTeamMembers = '3 Team Members';
          break;
        case 'Growth':
          planDescription = 'Perfect for managing large-scale accounts, offering tailored strategies and insights for optimal growth.';
          planCredits = 'Unlimited Credits';
          planAccounts = 'Unlimited Accounts';
          planTeamMembers = '10 Team Members';
          break;
        default:
          planDescription = 'Build momentum on social media';
          planCredits = 'N/A';
          planAccounts = 'N/A';
          planTeamMembers = 'N/A';
      }

      return (
        <div className={`col-lg-4 ${isDark ? '' : 'sub-color-dark'}`} key={index}>
         <div
  className={`card-container ${isCenterCard ? "card-container-active1" : ""}`}
  style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Centers items horizontally
    justifyContent: 'center', // Centers items vertically
    textAlign: 'center' // Centers text within each element
  }}
  onMouseEnter={(e) => e.currentTarget.classList.add('card-hover')}
  onMouseLeave={(e) => e.currentTarget.classList.remove('card-hover')}
>
  <h4 className="plan-title">{item.planType}</h4>
  <div className="plan-price">
    <span>
      {item.currencyType}
      {selectedPlan === 'Monthly' ? item.cost[0].amount : item.cost[1].amount}
    </span>
    /Month
  </div>
  <p className="plan-description">{planDescription}</p>
  <hr />
  <div style={{ textSizeAdjust: "initial" }} className="additional-details">
    <div className="detail-item">{planCredits}</div>
    <div className="detail-item">{planAccounts}</div>
    <div className="detail-item">{planTeamMembers}</div>
  </div>
  <ul className="features-list">
    {item.features?.map((feature, featureIndex) => (
      <li key={featureIndex} className="feature-item">
        <i className="fa fa-check" aria-hidden="true"></i>
        <span className={`${isDark ? '' : 'sub-color-dark'}`}>{feature}</span>
      </li>
    ))}
  </ul>
  <button className="mt-3" onClick={() => handleProceed(item)}>Proceed To Buy</button>
</div>

        </div>
      );
    });
  };

  useEffect(() => {
    getSubList();
  }, []);

  const handlePlanClick = (index) => {
    setActivePlan(index);
    setSelectedPlan(planarr[index]);
  };

  return (
    <>
      <div className="container mt-2">
        <div className="row">
          <div className="col-6 mx-auto text-center subs-header">
            <h2 className="main-header">Buy Subscription</h2>
            <p className='mb-3'>From personal use to small businesses to enterprises. There's a Superhero for everyone!</p>
          </div>
          <div className="col-12">
            <div className={`toggle-plan ${isDark ? '' : 'sub-color-dark'}`}>
              {planarr?.map((item, index) => (
                <span
                  key={index}
                  onClick={() => handlePlanClick(index)}
                  className={`toggle-option ${activePlan === index ? 'active-plan' : ''}`}
                >
                  {item}
                </span>
              ))}
            </div>
          </div>
          <div className={`col-lg-12 mx-auto ${isDark ? '' : 'sub-color-dark'}`}>
            <div className="row card-row">
              {getMonthlyPlan()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Subscription;
