import React, { useState } from 'react';
import { TagCloud } from 'react-tagcloud';
import { useDispatch, useSelector } from "react-redux";

const WordCloudComponent = () => {
  const { isDark } = useSelector((state) => state.common);
  const [words, setWords] = useState([
    { value: "React", count: 50 },
    { value: "JavaScript", count: 80 },
    { value: "HTML", count: 30 },
    { value: "CSS", count: 70 },
    { value: "Node", count: 60 },
    { value: "GraphQL", count: 40 },
    { value: "Redux", count: 55 },
    { value: "Hooks", count: 65 },
    { value: "Functional", count: 45 },
    { value: "Components", count: 75 },
    { value: "State", count: 35 },
    { value: "Props", count: 25 },
    { value: "Lifecycle", count: 20 },
    { value: "Webpack", count: 68 },
    { value: "Babel", count: 38 },
    { value: "TypeScript", count: 82 },
    { value: "Jest", count: 43 },
    { value: "Testing", count: 53 },
    { value: "API", count: 72 },
    { value: "Axios", count: 33 },
    { value: "Promises", count: 63 },
    { value: "Async", count: 73 },
    { value: "Await", count: 37 },
    { value: "JSON", count: 58 },
    { value: "RESTful", count: 48 },
    { value: "GraphQL", count: 92 },
    { value: "Apollo", count: 67 },
    { value: "Express", count: 51 },
    { value: "Sass", count: 32 },
    { value: "Bootstrap", count: 41 },
    { value: "Tailwind", count: 57 },
    { value: "Responsive", count: 56 },
    { value: "Flexbox", count: 29 },
    { value: "Grid", count: 31 },
    { value: "Design", count: 77 },
    { value: "UI", count: 24 },
    { value: "UX", count: 27 },
    { value: "Accessibility", count: 34 },
    { value: "SEO", count: 64 },
    { value: "Performance", count: 44 },
    { value: "Optimization", count: 54 },
    { value: "Security", count: 22 },
    { value: "DevOps", count: 62 },
    { value: "Git", count: 39 },
    { value: "Version Control", count: 49 },
  ]);

  return (
    <div className={`flex flex-col items-center justify-center w-full p-6 transition-colors duration-300 ${
      !isDark ? 'bg-black-900' : 'bg-gray-100'
    }`}>
      <h1 className={`text-3xl font-bold mb-5 ${
        !isDark ? 'text-gray-200' : 'text-gray-800'
      }`}>
        Keyword Insights
      </h1>
      <div className={`p-4 rounded-lg shadow-md ${
        !isDark ? 'bg-[#2D2D2D]' : 'bg-white'
      }`}>
        <TagCloud
          minSize={12}
          maxSize={35}
          tags={words}
          renderer={(tag, size) => (
            <span 
              key={tag.value} 
              style={{ 
                fontSize: `${size}px`, 
                margin: '3px', 
                padding: '5px',
                display: 'inline-block',
                color: !isDark ? '#ddd' : '#555',  // Conditional text color
                transition: 'color 0.3s ease',
              }} 
              className={!isDark ? 'hover:text-blue-300' : 'hover:text-blue-500'}
            >
              {tag.value}
            </span>
          )}
        />
      </div>
    </div>
  );
};


export default WordCloudComponent;