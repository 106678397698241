import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IMAGE_URL } from "../../api/API";
import { postApi, getApi, deleteApi, putApi } from "../../api/methods";
import { toast } from "react-toastify";
import axios from "axios";
import "./TeamPermissions.css";
import PostAd from "../Dashboard/PostAd";
import { Link } from "react-router-dom";

const TeamPermissions = () => {
  const userInfo = useSelector((state) => state.user.userData);
  const userPhoto = useSelector((state) => state.user.userPhoto);
  const userData = useSelector((state) => state.user.userData);
  const teamMembers = useSelector((state) => state.user.teamMembers);
  const [teamMembersLocal, setTeamMembersLocal] = useState(teamMembers);
  const { isDark } = useSelector((state) => state.common);
  const filterActiveTeamMembers = teamMembers.filter(
    (member) => member.ac_status === true
  );
  const [teamMembersCount, setTeamMembersCount] = useState(
    filterActiveTeamMembers?.length || 0
  );
  const [inviteTextBox, setInviteTextBox] = useState(false);
  const [inviteEmailText, setInviteEmailText] = useState("");
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [isFree, setIsFree] = useState(false); // State to manage the free tier
  const [planName, setPlanName] = useState("Free-tier");

  const getPlanName = async () => {
    let transactionData;
    try {
      transactionData = await getApi(
        `transaction/get-subscription-by-id/${userData.teamId}`
      );
      setPlanName(transactionData[0].plan_Name);
      console.log(planName)
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      return;
    }
  };

  useEffect(() => {
    const fetchInvitedMembers = async () => {
      try {
        const response = await getApi(
          `invite/get-team-members-by-teamid/${userInfo.teamId}`,
          { withCredentials: true }
        );
        setInvitedMembers(response.data.invitedMembers);
      } catch (error) {
        console.error("Error fetching invited members:", error);
      }
    };
    fetchInvitedMembers();
    getPlanName()
  }, [userInfo.teamId]);
  const handleMemberDelete = async (userId) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this member?"
    );
    if (confirmed) {
      const url = `/invite/change-team/${userId}`;
      try {
        const updatedUser = await putApi(url, null, { withCredentials: true });

        // Update state by filtering out the deleted member based on userId
        // dispatch(setTeamMembers(teamMembers.filter((member) => member.id !== userId)));
        setTeamMembersLocal((prevMembers) =>
          prevMembers.filter((member) => member.id !== userId)
        );

        // Success toast notification
        toast.success("Member removed successfully!");
        console.log("User updated:", updatedUser);
      } catch (error) {
        console.error("Error updating user:", error);
        // Error toast notification
        toast.error(
          error.response?.data?.message || "Failed to remove member."
        );
      }
    }
  };

  const handleDelete = async (memberEmail) => {
    const confirmed = window.confirm(
      "Are you sure you want to remove this invite?"
    );
    if (confirmed) {
      try {
        const response = await deleteApi(
          `/invite/removeInvitation/${memberEmail}`,
          null,
          { withCredentials: true }
        );

        // Check the response for success
        if (response.success) {
          // Update state by filtering out the deleted member
          setInvitedMembers(
            invitedMembers.filter((member) => member.emailTo !== memberEmail)
          );
          toast.success("Invite removed successfully!");
        } else {
          toast.error(response.message || "Failed to remove the invite.");
        }
      } catch (error) {
        console.error("Error removing invite:", error);
        toast.error(
          error.response?.data?.message || "Failed to remove invite."
        );
      }
    }
  };

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const transactionData = await getApi(
          `transaction/get-subscription-by-id/${userData.teamId}`
        );
        if (
          transactionData.length > 0 &&
          transactionData[0].plan_Name === "Free-Tier"
        ) {
          setIsFree(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
      }
    };

    checkSubscription();
  }, [userData.teamId]);

  const sendInviteLink = async () => {
    if (isFree) {
      toast.error("Upgrade your plan to invite more team members.");
      return;
    }

    let transactionData;
    try {
      transactionData = await getApi(
        `transaction/get-subscription-by-id/${userData.teamId}`
      );
    } catch (error) {
      console.error("Error fetching transaction data:", error);
      toast.error("Failed to fetch transaction data.");
      return;
    }

    if (transactionData && transactionData.length > 0) {
      const planName = transactionData[0].plan_Name;

      if (planName === "Free-Tier") {
        toast.error(
          "You are not allowed to invite team members on a free plan."
        );
        toast.info("Upgrade your plan to invite more team members.");
        return;
      }
    }

    if (!inviteEmailText || inviteEmailText.length === 0) {
      toast.error("Email field needs to be filled.");
      return;
    }

    if (!inviteEmailText.includes("@") || !inviteEmailText.includes(".")) {
      toast.error("Invalid email address.");
      return;
    }

    const [emailUsername, emailHosting] = inviteEmailText.split("@");
    const [emailHost, emailDomain] = emailHosting.split(".");

    if (
      emailUsername?.length > 3 &&
      emailHost?.length > 3 &&
      emailDomain?.length > 1
    ) {
      try {
        const response = await postApi(
          `invite/invite-member`,
          {
            email: inviteEmailText,
            teamId: userInfo.teamId,
            userId: userInfo.userId,
            invitingPerson: emailUsername,
          },
          { withCredentials: true }
        );

        const data = response;

        if (data.success === true) {
          setInviteEmailText("");
          setInviteTextBox(false);
          toast.success("Invite sent successfully!");
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error inviting team member:", error);
        toast.error(error.response?.data?.message || "Failed to send invite.");
      }
    } else {
      toast.error("Invalid email address.");
    }
  };

  return (
    <div className="relative w-full " style={{ height: "100vh" }}>
      {isFree && (
        <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center z-3">
          <div
            className={`  ${
              isDark ? "post-ad grediant-border" : "post-ad-dark"
            }  `}
            style={{ width: "50%" }}
          >
            {/* <div className="post-ad-img d-flex justify-content-center"> */}
            {/* <img src={`${IMAGE_URL}/images/Icons/dashboard/${icon}.svg`} /> */}
            {/* </div> */}
            <div className="post-box-text">
              {/* <h3 className="d-flex justify-content-center w-100 mb-3 text-sm xl:text-xl">
                  Content
                </h3> */}
              <p className="d-flex justify-content-center w-100 text-xs xl:text-lg">
                You have discovered a premium feature. To access all our
                features, you need to subscribe to one of our plans by clicking
                the button below.
              </p>
            </div>
            <div className="btn-create-post">
              <Link
                to="/buy-subscription"
                className="grediant-button"
                style={{
                  background:
                    " linear-gradient(126deg,#8A51FF -9.26%,#39B2FF 79.86%)",
                }}
                title="Upgrade"
              >
                Upgrade
              </Link>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          filter: isFree ? "grayscale(100%)" : "none",
          pointerEvents: isFree ? "none" : "auto",
        }}
      >
        <div>
          <h3 className="mb-2">
            <strong style={{ fontSize: "1.3rem" }}>Manage your team</strong>
          </h3>
        </div>
        <div>
          <h4>Team Members</h4>
          {teamMembersLocal &&
            teamMembersLocal?.map((member, i) => {
              if (member.userName?.length > 0) {
                return (
                  <div className="userinfo-list mt-2" key={i}>
                    <div className="d-flex align-items-center">
                      {member.linkedinProfile?.length > 0 ? (
                        <img
                          src={member.linkedinProfile}
                          className="avatar-img w-10 rounded-full"
                          style={{ borderRadius: "50%" }}
                        />
                      ) : (
                        <img
                          src={`${IMAGE_URL}/images/Icons/avatar.jpg`}
                          className="avatar-img w-10 rounded-full"
                          style={{ borderRadius: "50%" }}
                        />
                      )}
                      <p className="mb-0 ms-2">
                        <strong>{member.userName}</strong>
                      </p>
                      {member.teamPrimary == 1 ? (
                        <span className="badge bg-primary m-2">Admin</span>
                      ) : (
                        <>
                          <span className="badge bg-secondary m-2">Member</span>
                          {userInfo.teamPrimary && (
                            <i
                              className="fas fa-trash-alt text-danger ms-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleMemberDelete(member.userId)} // Assume there's a handleDelete function
                            ></i>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <p className="mb-0 me-3">
                        {" "}
                        <i
                          className="fa fa-user-plus"
                          aria-hidden="true"
                        ></i>{" "}
                        {[
                          member.createdAt?.split("T")[0].split("-")[2],
                          member.createdAt?.split("T")[0].split("-")[1],
                          member.createdAt?.split("T")[0].split("-")[0],
                        ].join("-")}
                      </p>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>

        <div>
          {invitedMembers.length > 0 ? (
            invitedMembers.map((member, i) => (
              <div className="userinfo-list mt-2" key={i}>
                <div className="d-flex align-items-center">
                  <img
                    src={`${IMAGE_URL}/images/Icons/avatar.jpg`}
                    className="avatar-img w-10 rounded-full"
                    style={{ borderRadius: "50%" }}
                  />
                  <p className="mb-0 ms-2">
                    <strong>{member.emailTo}</strong>
                  </p>
                  <span className="badge bg-warning m-2">Pending Invite</span>
                  <i
                    className="fa fa-trash text-danger ms-3 cursor-pointer"
                    onClick={() => handleDelete(member.emailTo)}
                  ></i>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}
        </div>x
        <div className="my-3">
          <p className="text-lg">
            {planName === "Pro"
              ? "You cannot invite team members on a free plan"
              : planName === "Creator"
              ? "Your plan allows up to 3 team members"
              : planName === "Growth"
              ? "Your plan allows up to 7 team members"
              : "You cannot invite team members on a free plan"}
          </p>
        </div>
        {/* //Your plan allows up to 4 team members */}
        {inviteTextBox && (
          <div className="my-2">
            <input
              className="form-control"
              type="email"
              value={inviteEmailText}
              onChange={(e) => setInviteEmailText(e.target.value)}
              placeholder="Email for invitation"
            />
          </div>
        )}

        {inviteEmailText?.length > 0 ? (
          <div className="team-btn">
            <button onClick={sendInviteLink}>
              {" "}
              <i className="fa-regular fa-paper-plane"></i> SEND
            </button>
          </div>
        ) : (
          <div className="team-btn">
            {userInfo.teamPrimary && (
              <button onClick={() => setInviteTextBox(!inviteTextBox)}>
                {" "}
                + INVITE NOW
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamPermissions;
