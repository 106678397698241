// convert 24 hours time to 12 hours time with AM/PM
export const convertTo12HourFormat = (time24Hour) => {
    const [hours, minutes] = time24Hour.split(":");
    let ampm = "AM";
    let hours12 = parseInt(hours);

    if (hours12 >= 12) {
      ampm = "PM";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    if(hours12 < 10){
      hours12 = `0${hours12}`
    }

    const time12Hour = `${hours12}:${minutes} ${ampm}`;
    return time12Hour;
  }