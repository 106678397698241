import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { BASE_URL } from '../../api/API';
import { useDispatch, useSelector } from "react-redux";

const PostTypeGraph = ({ userId }) => {
  const { isDark } = useSelector((state) => state.common);
  const [data, setData] = useState([]);
  const [displayedDataKey, setDisplayedDataKey] = useState('likes');

  // Sample data simulating the expected API data format
  const sampleData = {
    video: { likes: 50, comments: 20 },
    article: { likes: 30, comments: 10 },
    image: { likes: 70, comments: 30 },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Uncomment the following lines to fetch real data
        // const response = await axios.get(`${BASE_URL}post-analytics/user/${userId}/summary-by-type`);
        // const formattedData = transformData(response.data);

        // Use sample data for testing
        const formattedData = transformData(sampleData);
        setData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const transformData = (apiData) => {
    return Object.keys(apiData).map((key) => ({
      postType: key,
      likes: apiData[key].likes,
      comments: apiData[key].comments,
    }));
  };

  const handleSelectChange = (event) => {
    setDisplayedDataKey(event.target.value);
  };

  return (
    <div className={`flex flex-col items-center w-full p-4 transition-colors duration-300 ${
      !isDark ? 'bg-black-900' : 'bg-gray-100'
    }`}>
      <h3 className={`text-2xl font-bold mb-8 ${
        !isDark ? 'text-gray-200' : 'text-gray-800'
      }`}>
        Post Type Analysis
      </h3>
      
      <div className={` mt-4 flex flex-col items-center shadow-md justify-center w-full ${
        !isDark ? 'bg-[#2D2D2D]' : 'bg-white'
      } rounded-lg p-4`}>
        <div className="w-full flex justify-end items-center mb-4">
          <select 
            onChange={handleSelectChange} 
            value={displayedDataKey} 
            className={`p-2 border rounded ${
              !isDark ? 'border-gray-600 bg-gray-700 text-gray-200' : 'border-gray-300 bg-white text-gray-800'
            }`}
          >
            <option value="likes">Likes</option>
            <option value="comments">Comments</option>
          </select>
        </div>

        <ResponsiveContainer width="95%" height={400}>
          <BarChart 
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke={!isDark ? '#444' : '#ccc'} />
            <XAxis dataKey="postType" stroke={!isDark ? '#ddd' : '#333'} />
            <YAxis stroke={!isDark ? '#ddd' : '#333'} />
            {/* Uncomment the line below to enable tooltips */}
            {/* <Tooltip contentStyle={{ backgroundColor: !isDark ? '#555' : '#fff', color: !isDark ? '#ddd' : '#333' }} /> */}
            <Legend iconType="circle" wrapperStyle={{ color: !isDark ? '#ddd' : '#333' }} />
            <Bar dataKey={displayedDataKey} fill={displayedDataKey === 'likes' ? '#8884d8' : '#82ca9d'} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PostTypeGraph;
